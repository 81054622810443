#consent-notice {
	padding: 1rem 1rem;
	display: none;
	text-align: center;
	position: fixed;
	bottom: 0;
	width: calc(100% - 2rem);
	background: $color--dark;
	color: $color--lighter;
}
#consent-notice span {
	margin-right: 1rem;
}
.btn {
	cursor: pointer;
	display: inline-block;
	width: auto;
	border: none;
	padding: $space-xs $space-s;
	font-size: $font-xs;
}
#consent-notice span a {
	color: inherit;
	text-decoration: underline;
	text-decoration-color: $color--accent;
}

#consent-notice button.btn {
	margin-left: 0.5rem;
}

#consent-notice button.btn.manage-consent {
	background: rgba($color--accent,0.2);
	color: $color--lighter;
}

#consent-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	display: none;
	background: rgba($color--dark,0.75);
	z-index: 999999;
	overflow: auto;
	cursor: pointer;
}
#consent-overlay.active {
	display: flex;
}
#consent-overlay > div {
	background: white;
	width: 100%;
	max-width: 30rem;
	padding: 1.75rem;
	margin: auto;
	cursor: initial;
}
#consent-overlay > div > div {display: flex; align-items: flex-start; gap: 0.5rem;}
#consent-overlay h3 {
	color: $color--darker;
	margin-bottom: $space-xs;
}
#consent-overlay input {
	border: none;
	background: none;
	
}
#consent-overlay label {display: block; margin-bottom: 1rem;}

@media (max-width: 767px) {
	#consent-overlay > div {padding: 1.75rem 1rem;}
	#consent-notice span {display: block; padding-top: 3px; margin-bottom: 1.5rem;}
	#consent-notice button.btn {position: relative; bottom: 4px;}
}