/*
Theme Name: Xenia
*/

@import "abstracts/mixins";
@import "abstracts/variables";
@import "abstracts/typography";
@import "abstracts/base";

@import "components/cookies";
@import "components/tabs";
@import "components/form";
@import "components/menu";
@import "components/layout";

@import "pages/page";
@import "pages/phototext";
@import "pages/about";
@import "pages/gallery";
@import "pages/frontpage";
@import "pages/enquire";
