/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Home page
~~~~~~~~~~~~~~~~~~~~~~~~~ */

.home-content {
	display: flex;
	gap: 0 $space-m;
	flex-flow: column-reverse wrap;
	padding: 0 $space-xs;
	@include respond-to('medium') {
		padding: $space-l $space-l $space-l 0;
	}
	@include respond-to('large') {
		flex-flow: row nowrap;
		align-items: end;
		justify-content: flex-end;
		padding: $space-l $space-l $space-l 0;
		height: calc(100% - $space-l*2);
	}
	
	.home-header {
		margin-top: $space-xs;
		padding: 0 0 $space-s 0;
		@include respond-to('large') {
			min-width: rem(164);
			text-align: right;
			margin: 0;
			padding: 0 0 $space-xxs $space-xs;
		}
	}
	.home-project {
		img {
			max-height: calc(100vh - $space-l*2);
		}
		.image-link {
			border: none;
			transition: all .4s ease;
			&:hover {
				opacity: 0.9;
			}
		}
	}
}