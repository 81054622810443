/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Gallery page
~~~~~~~~~~~~~~~~~~~~~~~~~ */
.page-content--galleries {
	display: grid;
	grid-template-columns: 1fr;
	gap: 0 $space-m;
	padding: $space-s $space-xs;
	@include respond-to('medium') {
		padding: calc($space-xl*2.35) $space-xl 0 0;
		grid-template-columns: 1fr 1fr;
	}
	@include respond-to('large') {
		padding: calc($space-xl*2.35) $space-xl 0 0;
		grid-template-columns: 1fr 1fr 1fr;
	}
	.gallery-thumb {
		margin-bottom: $space-xs;
		transition: all .4s ease;
		&:hover {
			opacity: 0.9;
		}
	}
		a {
			text-decoration: none;
		}
}
.gallery {
	margin-bottom: 2rem;
	@include respond-to('large') {
		margin-bottom: 0;
	}
	&--images {
		padding: 0 $space-xs;
		@include respond-to('large') {
			padding: calc($space-xl*2.35) $space-xl 0 0;
		}
	}
}
.pswp {
	.pswp__counter {
		color: $color--darker;
		text-shadow: none;
	}
	.pswp__icn {
		fill: $color--darker;
		color: $color--darker;
	}
	.pswp__icn {
	top: 14px;
	left: 9px;
	width: 24px;
	height: 24px;

	}
	.pswp__button--arrow .pswp__icn {
		top: 50%;
		margin-top: -30px;
		width: 30px;
		height: 30px;
		background: none;
		border-radius: 0;
	}
}
.pswp img {
	object-fit: contain;
	max-width: 100%;
	background-color: $color--lighter;	
	padding: $space-xxs 0;
}
.pswp-gallery {
	display: grid;
	grid-template-columns: 1fr;
	padding: 0;
	gap: $space-l;
	
	@include respond-to('medium') {
		grid-template-columns: 1fr 1fr;
	}
	@include respond-to('large') {
		grid-template-columns: 1fr 1fr 1fr;
	}
	
	a {
		&:hover {
			cursor: url('/images/full-screen.svg'), auto;
			opacity: 0.9;
		}
		transition: all .4s ease;
		&:nth-child(1) {
			@include respond-to('large') {
				grid-area: 1 / 1 / 2 / 2;
				justify-self: end;
				img {
					max-height: calc(80vh - $space-xl);
				}				
			}
		}
		&:nth-child(2) {
			@include respond-to('large') {
				grid-area: 1 / 2 / 2 / 4;
				justify-self: end;
				img {
					max-height: calc(80vh - $space-xl);
				}				
			}
		}
		@include respond-to('medium') {
			&.col2 {
				grid-column: span 2;
			}
			&.col3 {
				grid-column: span 3;
			}
		}
	}
}

.pswp__bg { background: $color--lighter; }