/*  ==========================================================================
    General
    ========================================================================== */
    html {
        min-height: 100vh;
    }
    body {
        background-color: $color--lighter;
        color: $color--dark;
        display: grid;
        font-family: $font--primary;
        font-size: $font-s;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr auto;
        align-content: start;        
        min-height: 100vh;
        margin: 0;
        padding: 0;
        text-rendering: optimizeLegibility;

        @include respond-to('medium') {
            grid-template-columns: 0.5fr 3fr;
            grid-template-rows: auto 1fr;
        }
    }

/*  ==========================================================================
    Images
    ========================================================================== */

    img {
      display: block;
      height: auto;
      max-width: 100%;
    }


/*  ==========================================================================
    Elements
    ========================================================================== */

	address { margin: 0 0 1.5rem; }
    

    a {
	  color: $color--dark;
      display: inline-block;
	  &:visited,
      &:active {}
      &:hover {
	    color: $color--accent;
        path {
            fill: $color--accent;
        }
        }
        p &,
        ul &,
        ol & {
            text-decoration: underline;
        }
    }
    
    ol,ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    
