

spicy-sections {
  /* Big hand-wave over how we'd ultimately express this, but for this custom element, this is how you inform when you'd like to emply which affodances 'collapse', 'tab-bar' and 'exclusive-collapse' are the available affordances.  Anything else is, effectively "plain" or "none". It is only read once.
  */
  --const-mq-affordances: [screen and (max-width: 40em) ] collapse | [screen and (min-width: 60em) ] tab-bar;
  display: block;

  h2 { margin: 0; }
  h2:not([affordance] *) { margin-bottom: 0.25rem; }
  h2:not([affordance] *):not(:first-child) { margin-top: 1.5em; }
  + * { margin-top: 0; }
}


[affordance="collapse"] {
  --heading-size: 1rem;
  --demo-border: dotted tomato;
}

/* structured content */
spicy-sections:not([affordance]) {
  --demo-border: dashed teal;
}

/* tabs */
[affordance="tab-bar"] {
  --demo-border: solid mediumvioletred;
}

/* the pseudo element created around the tabs, when it exists */
::part(tab-list) {
  display: flex;
  gap: $space-l;
}

/* only when they're tabs */
[affordance="tab-bar"] h2 {
  padding: 0.125rem 0;
  cursor: pointer;
  font-size: $font-s;
}

/* Only when they're tabs and selected */
[affordance="tab-bar"] h2[tabindex="0"] {
  background: $color--lighter;
  border: 0;
  color: $color--accent;
}

/* Tabs that aren't selected */
[affordance="tab-bar"] h2:not([tabindex="0"]) {
  color: rgba($color--dark,0.5);
  background-color: rgba($color--dark,0.1);
  padding: 0.15rem 0.25rem;
  border-radius: 2px;
}

/* content, when it's tabs */
[affordance="tab-bar"] [role="tabpanel"] {
  background: $color--lighter;
  padding: $space-m 0;
}
