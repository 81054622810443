.form {
	display: flex;
	flex-flow: column wrap;
	gap: $space-s 0;
	@include respond-to('large') {
		gap: $space-l 0;
	}
	.field-wrapper {
		display: flex;
		flex-flow: column wrap;
		gap: 0 $space-m;
		label {
			font-size: $font-xxs;
			text-transform: uppercase;
			margin-bottom: 2px;
		}
		@include respond-to('large') {
			flex-flow: column wrap;
		}
		input[type="text"],
		input[type="email"]
		 {
			border: none;
			border-bottom: $color--dark 1px solid;
			border-radius: 0;
			background-color: $color--lighter;
			padding: $space-xxs 0;
			font-size: $font-s;
			flex: 1 1 auto;
			-webkit-appearance: none;
			&:focus {
				border-top: 1px solid $color--accent;
				border-bottom: 1px solid $color--accent;
				outline: $color--accent;
			}
		}
		textarea {
			border: none;
			border-radius: 0;
			border-bottom: $color--dark 1px solid;
			min-height: rem(128);
			font-size: $font-s;
			padding: $space-xxs 0;
			width: 100%;
			-webkit-appearance: none;
			&:focus {
				border-top: 1px solid $color--accent;
				border-bottom: 1px solid $color--accent;
				outline: $color--accent;
			}
		}
		select {
			width: 100%;
			-webkit-appearance: none;
			padding: 0.75rem 1rem;
			font-size: $font-s;
			color: $color--dark;
			&:focus {
				border: 2px solid $color--accent;
				outline: $color--accent;
			}
		}
		.privacy {
			input {
				
			&:focus {
				border: 2px solid $color--accent;
				outline: $color--accent;
			}
			}
		}
	}
}
.button {
	-webkit-appearance: none;
	background-color: $color--dark;
	border: 2px solid $color--dark;
	padding: $space-xs $space-s ;
	font-weight: normal;
	font-size: $font-m;
	color: $color--lighter;
	display: block;
	justify-self: start;
	align-self: start;
	text-decoration: none;
	cursor: pointer;
	width: 100%;
	@include respond-to('large') {
	max-width: rem(200);
		display: inline-block;
	}
	&:hover {
		background-color: $color--accent;
		border-color: $color--accent;
	}
	&:focus {
		border: 2px solid $color--accent;
		outline: $color--accent;
		background-color: $color--accent;
	}
}