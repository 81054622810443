/* ==========================================================================
   Menu
   ========================================================================== */
	.globalNav {
		padding: 0;
		@include respond-to('medium') {
			padding: 0 $space-s;
		}
		@include respond-to('large') {
			padding: 0 $space-xl;
		}
		&-menu {
			list-style: none;
			padding: 0 $space-xs ;
			margin: 0;
			display: flex;
			flex-flow: row nowrap;
			justify-items: flex-start;
			gap: 0 $space-m;
			
			@include respond-to('medium') {
				padding: 0 $space-s 0 $space-m;
				flex-flow: column nowrap;
			}
			
			li {
				padding: 0;
				margin: 0 0 $space-xs 0;
			}
		}
		&-link {
			margin: 0;
			padding: 0;
			display: inline-block;
			font-size: $font-s;
			text-decoration: none;
			&:hover {
				color: $color--accent;
				text-decoration: none;
			}
			&--active {
				text-decoration: underline;
				color: $color--darker;
				&:hover {
					text-decoration: underline;
					color: $color--darker;
				}
				
				// &:after {
				// 	content: "•";
				// 	font-size: rem(28);
				// 	margin: 0;
				// 	padding: 0;
				// 	line-height: 0;
				// 	color: $color--accent;
				// 	display: inline-block;
				// 	vertical-align: baseline;
				// }
			}
		}
	}