.siteHeader {
	padding:0;
	grid-column: 1/2;
	grid-row: 1/2;
	
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
}
.brandLogo {
	padding: $space-s $space-xs;
	@include respond-to('small') {
		padding: $space-m $space-xs;
	}
	@include respond-to('medium') {
		padding: $space-xl;
	}
}

.siteAside {
	grid-column: 1/2;
	grid-row: 2;
}
.social {
	display: none;
	@include respond-to('large') {
		display: block;
		padding: $space-l 0 0 $space-xl;
		&-link {
			font-size: $font-xs;
			margin: 0;
			padding: 0 $space-s 0 $space-m;
			line-height: 1;
			display: inline-flex;
			align-items: center;
			text-decoration: none;
			transition: all .4s ease;
			&:hover {
				background-color: rgba($color--accent,0.1);
				border-radius: 999px;
				padding: 0.25rem 0.5rem;
				margin: -0.25rem 0;
			}
		}
	}
	&--mobile {
		display: block;		
		padding: 0 $space-xs 0 $space-m;
		@include respond-to('medium') {
			display: none;
		}
	}
}


.siteMain {
	// display: grid;
	// grid-template-columns: 1fr;
	// grid-auto-rows: auto;
	// align-items: start;
	// 
	@include respond-to('small') {
		grid-row: span 2;
	}
}

.siteFooter {
	   padding: $space-l $space-xs;
	   @include respond-to('medium') {
		   padding: $space-xl;
		   position: fixed;
		   left: $space-xl;
		   bottom: 2rem;		   
		   padding: 0;
	   }
	   .disclaimer {
		   font-size: $font-xs;
		   a {
			   text-decoration: none;
			   color: $color--dark;
			   &:hover {
				   color: $color--accent;
			   }

		   }
		   @include respond-to('medium') {
			   transform: rotate(-90deg);
			   transform-origin: top left;  
		   }
	   }
   }