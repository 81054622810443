/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Phototext page
~~~~~~~~~~~~~~~~~~~~~~~~~ */

.phototext {
}
.reading-time {
	font-size: $font-xs;
	color: $color--dark;
	margin: $space-xxs 0;
	color: rgba($color--dark,0.75);
}