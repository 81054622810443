/* ==========================================================================
Variables
========================================================================== */

// TYPE
$font--primary:         'basier_circleregular','Helvetica', sans-serif;
$font--primary-medium:  'basier_circlemedium','Helvetica', sans-serif;

$font-l:                rem(18);
$font-m:                rem(16);
$font-s:                rem(14);
$font-xs:               rem(12);
$font-xxs:               rem(10);

$font-l--mobile:        rem(18);
$font-m--mobile:        rem(16);
$font-s--mobile:        rem(14);
$font-xs--mobile:       rem(11);

// COLOR
$color--dark:           #4B5448;
$color--darker:         #1A1A1A;
$color--lighter:        #ffffff;
$color--accent:         rgb(117, 177, 103);

// SPACING
$space-huge:           rem(264);
$space-xl:             rem(60);
$space-l:              rem(40);
$space-m:              rem(32);
$space-s:              rem(24);
$space-xs:             rem(16);
$space-xxs:            rem(8);

// LAYOUT
$breakpoints: (
  'small':              (min-width: 400px),
  'medium':             (min-width: 768px),
  'large':              (min-width: 1024px),
  'huge':               (min-width: 1600px),
);

$pageWidth:             rem(1400);