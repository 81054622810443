@font-face {
  font-family: "basier_circleregular";
  src: url('../fonts/basiercircle-regular-webfont.woff2') format('woff2'), url('../fonts/basiercircle-regular-webfont.woff') format('woff');
}
@font-face {
  font-family: "basier_circlemedium";
  src: url('../fonts/basiercircle-medium-webfont.woff2') format('woff2'), url('../fonts/basiercircle-medium-webfont.woff') format('woff');
}

/*	==========================================================================
  	Typography
  	========================================================================== */

		h1 {
			font-size: $font-m--mobile;
			line-height: 1;
			margin: 0 0 $space-xxs 0;
			padding: 0;
			font-weight: normal;
			@include respond-to('medium') {
				font-size: $font-m;
				margin: 0 0 $space-s 0;
			}
		}

		h2 {
			font-size: $font-s--mobile;
			font-weight: 400;
			line-height: 1.2;
			margin: 0 0 0.4rem 0;
			padding: 0;
			@include respond-to('medium') {
				font-size: $font-s;
			}
		}
		h3 {
			font-size: $font-s--mobile;
			font-weight: 400;
			line-height: 1.2;
			margin: 0;
			padding: 0;
			@include respond-to('medium') {
				font-size: $font-s;
			}
		}


		p,span {
			font-size: $font-s--mobile;
			line-height: 1.5;
			margin: 0 0 0.5rem 0;
			padding: 0;
			
			@include respond-to('medium') {
				font-size: $font-s;
			}
		}

		b,
		strong {
			font-weight: inherit;
			font-family: $font--primary-medium;
		 }

		small {
			font-size: $font-xs;
			line-height: 1.2;
		}
		
		i,em {
			font-family: $font--primary;
			font-style: unset;
			display: inline-block;
		}
		