/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Generic styles
~~~~~~~~~~~~~~~~~~~~~~~~~ */



.page-content {
	max-width: calc($pageWidth / 2);
	&--columns {
		max-width: $pageWidth;
		margin: 0;
		padding: 0 $space-xs;
		display: grid;
		grid-template-columns: 1fr;
		gap: $space-xs;
		@include respond-to('medium') {
			grid-template-columns: repeat(12,1fr);
			gap: $space-xs $space-xs;
			padding: calc($space-xl*2.35) 0 0 0;
		}
		
		@include respond-to('large') {
			gap: 0 0;
			// word-wrap: break-word;
			// word-break: break-all;
			.home & {
				align-content: end;
			}
		}
	}
	&--gallery {
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: 1fr;
		gap: $space-xl $space-m;
	}
	&--text {
		height: calc(100vh - 4rem);
		overflow: scroll;
		padding-right: 2rem;
	}
}

	
// LISTS

.layout {
	&--list {
		padding: 0;
		margin: 0;
		@include respond-to('medium') {
			padding: calc($space-xl*2.5) 0;
		}
		.list {
			padding: $space-s 0;
			margin: 0;
			counter-reset: item;
			list-style-type: none;

			list-style-position: inside;
			position: relative;
			.ul-article {
				position: relative;
				margin: 0 0 0 $space-xs;
				padding: 0;
			}
			.ol-article {
				position: relative;
				margin: 0 0 $space-xs $space-xs;
				padding: 0;
				.article-link {
					&:hover {
						color: $color--accent;
						text-decoration: none;
					}
				}
				@include respond-to('medium') {
					margin: 0 0 $space-xs 0;
					
				}
				&:before {
					content: "";
					margin: 0 $space-s 0 0;
					content: counter(item, decimal-leading-zero) " "; 
					counter-increment: item;
				}
				
			}
		}
	}
}

// CONTENT BLOCKS

.block-info {
	grid-column: 1 / -1;
	@include respond-to('medium') {
		grid-column: span 4;
	}
	@include respond-to('huge') {
		grid-column: span 5;
	}
}

.content-header {
	padding: $space-s 0;
	@include respond-to('large') {
		padding: 0;
	}
}

.block-content {
	grid-column: 1/-1;
	padding: 0;
	justify-self: end;
	@include respond-to('medium') {
		grid-column: 6/span 7;
		padding: 0 $space-s 0 0;
		height: calc(100vh - $space-xl*3);
		overflow-y: scroll;
	}
	@include respond-to('large') {
		height: calc(100vh - $space-xl*3);
		grid-column: 6 / span 6;
	}
	@include respond-to('huge') {
		grid-column: 7 / span 7;
	}
}

// LINKS & BUTTONS
.direct-link {
	text-decoration: none;
	border-top: 1px dotted $color--dark;
	border-bottom: 1px dotted $color--dark;
	padding: $space-xxs 0;
	display: inline-block;
	svg {
		transition: all .4s ease;
		margin-right: 0;
	}
	&:hover {
		border-top: 1px dotted $color--accent;
		border-bottom: 1px dotted $color--accent;
		color: $color--accent;
		svg {
			transform: translateX($space-xs);
			path {
				fill: $color--accent;
			}
		}
	}
	&--small {
		@extend .direct-link;
		font-size: $font-xs;
		padding: 2px 0;
		display: inline-flex;
		flex-flow: row nowrap;
		align-items: center;
		align-content: flex-start;
		svg {
			transform: scale(75%);
			padding-top: 2px;
		}
	}
}

.external-link {
	margin-bottom: 4px;
}

.article-link {
	text-decoration: none;
	svg {
		transition: all .4s ease;
		margin-right: 0;
	}
	&:hover {
		svg {
			transform: translateX($space-xs);
		}
	}
}