/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
About page
~~~~~~~~~~~~~~~~~~~~~~~~~ */

.about {
	& .siteMain {
		h2 {
			color: rgba($color--dark,0.5);
			margin: $space-m 0 $space-xs 0;
		}
		ul {
			margin-bottom: 1rem;
			li {
				margin-bottom: $space-xs;
			}
		}
		p {
		}
	}
	.thumb--img {
		grid-column: 1/-1;
		@include respond-to('medium') {
			grid-column: span 3;
		}
	}
	.publications {
		.publication {
			&-description {
				color: rgba($color--dark,0.75);
			}
		}
	}
	
}